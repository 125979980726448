<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        x-small
        v-bind="attrs"
        v-on="on"
        class="pa-4"
        @click.stop="copyToClipboard()"
      >
        <v-icon v-if="isCopied" color="green">mdi-check</v-icon>
        <v-icon v-else>mdi-content-copy</v-icon>
      </v-btn>
    </template>
    <span>{{ tooltipText }}</span>
  </v-tooltip>
</template>
<script>
export default {
  name: 'CopyToClipboard',
  props: {
    data: {
      type: String,
      required: true,
    },
    tooltipText: {
      type: String,
      default: 'Copy',
    },
  },
  data () {
    return {
      isCopied: false,
      timex: null,
    }
  },
  methods: {
    copyToClipboard: function () {
      clearTimeout(this.timex)
      navigator
        .clipboard
        .writeText(this.data)
        .then(() => {
          this.isCopied = true
          this.timex = setTimeout(() => { this.isCopied = false }, 5000)
        })
    },
  },
}
</script>
